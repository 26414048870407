import React from "react";
import { useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthContext, AuthProvider } from "./Auth";

import "antd/dist/antd.css";
import "./App.css";
import Loading from "./components/Loading";
import { LoadingBackground } from "./views/LoginLayout/styled";
import CommentsDrawer from "./containers/CommentsDrawer";
import { Alert, Button } from "antd";
import {
  useProjectionRequirements,
  useUiOptions,
  usePracticeServices,
  useInformation,
  useInternalReasons,
  useIntercomData,
} from "./api/fetchOnce";
import ErrorBoundary from "./components/ErrorBoundary";
import useReadOnly from "./hooks/useReadOnly";
import { ValidationProvider } from "./utils/validation";
import PricingDrawer from "./containers/PricingUI/PricingDrawer";
import Banner from "./components/Banner/Banner";

const AdvisorApp = React.lazy(() => import("./AdvisorApp"));
const ParaplannerApp = React.lazy(() => import("./ParaplannerApp"));

const AppContainer = ({ children, dataTestAttr }) => {
  useProjectionRequirements();
  useUiOptions();
  usePracticeServices();
  useInformation();
  useInternalReasons();
  useReadOnly();
  useIntercomData();

  return (
    <div
      style={{
        display: "block",
        margin: "0 auto",
        // Container Width
        maxWidth: 1584,
        position: "relative",
      }}
      data-test={dataTestAttr}
    >
      {children}
    </div>
  );
};

const LoadingScreen = () => {
  return (
    <LoadingBackground>
      <Loading subtext="Loading..." color="black" />
    </LoadingBackground>
  );
};

const NoRoleApp = () => {
  return (
    <LoadingBackground>
      <Loading
        subtext="Your role isn't 'advisor' or 'paraplanner'"
        color="error"
      />
    </LoadingBackground>
  );
};

const NoRealmApp = () => {
  const {
    parsedToken: {
      realm_access: { realm },
    },
  } = useContext(AuthContext);

  return (
    <LoadingBackground>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ color: "red" }}>You didn't specify a 'realm'...</p>
        {realm && (
          <Button type="ghost">
            <Link to={`/${realm}`}>Redirect</Link>
          </Button>
        )}
      </div>
    </LoadingBackground>
  );
};

const AppRouter = () => {
  const {
    parsedToken: {
      realm_access: { roles },
      planpod_dealer_group,
    },
  } = useContext(AuthContext);

  let roleApp = NoRoleApp;
  let dataTestAttr = "";

  if (roles && roles.includes("advisor")) {
    roleApp = AdvisorApp;
    dataTestAttr = "auth-app";
  } else if (roles && roles.includes("paraplanner")) {
    roleApp = ParaplannerApp;
    dataTestAttr = "auth-app";
  } else {
    roleApp = NoRoleApp;
    dataTestAttr = "";
  }


  const handleLinkClick = () => {
    console.log("Pseudo link clicked!");
  };

  const starStruckEmoji = "\u{1F929}";
  const sparklesEmoji = "\u{2728}";

  const onClose = e => {
    sessionStorage.setItem(`alert-dismissed`, new Date());
  };

  return (
    <AppContainer dataTestAttr={dataTestAttr}>
      <Router>
        {/* ri and other banner section*/}
        {["consultum", "ri"].includes(
          planpod_dealer_group
        ) &&
          !sessionStorage.getItem("alert-dismissed") && (
            <div
              style={{
                position: "fixed",
                top: "20px",
                left: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% - 200px)",
                zIndex: 9999,
              }}
            >
              <Alert
                className="alert-banner"
                description={
                  <div
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      // marginTop: "8px",
                      // marginBottom: "8px",
                      fontSize: "16px",
                      color: "#FFF",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px" }}>
                      {/* 🎉 */}
                      {/* New: Digital SoA! */}
                      {/* We’re back! */}
                    </span>
                    <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                      {/* Visualise your advice like never before! */}
                      Have your SoA document converted into our Rhombus approved Digital Presentation for just $45+GST!✨
                    </span>
                    <span style={{ marginLeft: "15px" }}>
                      <a
                        // href="https://digitalsoa.com.au/"
                        href="https://present.planlogic.com.au/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button-link"
                      >
                        <button
                          style={{
                            backgroundColor: "#FFF",
                            color: "#6161FF",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "40px",
                            fontSize: "12px",
                          }}
                        >
                          Learn more
                        </button>
                      </a>
                    </span>
                  </div>
                }
                type="error"
                closable
                onClose={onClose}
                style={{
                  fontFamily: "Lato, sans-serif",
                  backgroundColor: "#6161FF",
                  borderRadius: "40px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              />
            </div>
          )}
        {/* asvw banner section*/}
        {/* add here "asvw" */}
        {['asvw'].includes(
          planpod_dealer_group
        ) &&
          !sessionStorage.getItem("alert-dismissed") && (
            <div
              style={{
                position: "fixed",
                top: "20px",
                left: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% - 200px)",
                zIndex: 9999,
              }}
            >
              <Alert
                className="alert-banner"
                description={
                  <div
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      // marginTop: "8px",
                      // marginBottom: "8px",
                      fontSize: "16px",
                      color: "#FFF",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px" }}>
                      {/* 🎉 */}
                      {/* New: Digital SoA! */}
                      {/* New : */}
                    </span>
                    <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                      {/* Visualise your advice like never before! */}
                      Have your SoA document converted into our Digital Presentation for just $45+GST!✨ 
                    </span>
                    <span style={{ marginLeft: "15px" }}>
                      <a
                        href="https://www.digitalsoa.com.au/"
                        // href="https://present.planlogic.com.au/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button-link"
                      >
                        <button
                          style={{
                            backgroundColor: "#FFF",
                            color: "#6161FF",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "40px",
                            fontSize: "12px",
                          }}
                        >
                          Learn more
                        </button>
                      </a>
                    </span>
                  </div>
                }
                type="error"
                closable
                onClose={onClose}
                style={{
                  fontFamily: "Lato, sans-serif",
                  backgroundColor: "#6161FF",
                  borderRadius: "40px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              />
            </div>
          )}
        <Banner></Banner>
        {/* <CommentsDrawer /> */}
        <PricingDrawer />
        <React.Suspense fallback={<LoadingScreen />}>
          <Switch>
            <Route path="/:realm" component={roleApp} />
            <Route path="/" component={NoRealmApp} />
          </Switch>
        </React.Suspense>
      </Router>
    </AppContainer>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const App = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ValidationProvider>
            <AppRouter />
          </ValidationProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
export default App;
